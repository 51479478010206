<template>
  <VueGoodTable
    mode="remote"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
    @on-row-click="params => $emit('on-row-click', params)"
    :totalRows="totalRecords"
    :isLoading="api.isLoading"
    :pagination-options="{
      enabled: true,
      perPage: searchParams.perPage
    }"
    :sort-options="{
      enabled: true
    }"
    :search-options="{
      enabled: false
    }"
    :select-options="{
      enabled: canSelectRows,
      selectionInfoClass: 'flex items-center'
    }"
    :rows="rows"
    :columns="columnData"
    :fixed-header="$breakpoint.smAndUp"
    max-height="70vh"
    class="data-table"
    :class="{ 'no-data-yet': rows.length === 0, 'has-data': rows.length > 0 }"
    styleClass="vgt-table striped"
  >
    <div slot="emptystate">
      <UtilMessage type="information">No data for this view</UtilMessage>
    </div>

    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field === 'status'">
        <UtilTagStatus :status="props.row.status" medium>
          {{ props.formattedRow[props.column.field] }}
        </UtilTagStatus>
      </span>

      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>

    <template slot="loadingContent">
      <template v-if="rows.length === 0">
        <UtilPlaceholder v-for="i in 20" :key="i" class="h-12 mb-1" />
      </template>

      <template v-else>
        <div class="bg-white rounded shadow-lg px-6 py-4">
          <FontAwesomeIcon :icon="faSpinnerThird" spin fixedWidth class="mr-2" />
          Loading
        </div>
      </template>
    </template>

    <div slot="selected-row-actions">
      <UtilButton color="primary" small class="ml-6">Delete Selected</UtilButton>
    </div>
  </VueGoodTable>
</template>

<script>
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'
import { VueGoodTable } from 'vue-good-table'
import UtilMessage from '@/components/utils/UtilMessage'
import UtilButton from '@/components/utils/UtilButton'
import UtilPlaceholder from '@/components/utils/placeholders/UtilPlaceholder'
import UtilTagStatus from '@/components/utils/UtilTagStatus.vue'

export default {
  name: 'UtilTableData',
  components: {
    VueGoodTable,
    UtilMessage,
    UtilButton,
    UtilPlaceholder,
    UtilTagStatus
  },
  props: {
    api: {
      type: Object,
      required: true
    },
    apiPath: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    dataPath: {
      type: String,
      default: 'data'
    },
    perPage: {
      type: Number,
      default: 20
    },
    initialSort: {
      type: Object,
      validator: function ({ field, type }) {
        return field && type
      }
    },
    canSelectRows: Boolean
  },
  data() {
    return {
      rows: [],
      columnData: [...this.columns],
      totalRecords: 0,
      searchParams: {
        columnFilters: {},
        sort: [],
        page: 1,
        perPage: 20
      },
      faSpinnerThird
    }
  },
  watch: {
    '$route.query'(newValue) {
      if (newValue.tableParams) {
        let currentParams = JSON.stringify(this.searchParams)

        if (currentParams === newValue.tableParams) {
          return false
        }

        this.searchParams = JSON.parse(newValue.tableParams)

        this.fetchData()
      }
    }
  },
  created() {
    this.searchParams.perPage = this.perPage

    if (this.initialSort) {
      this.searchParams.sort = [this.initialSort]
    }

    if (this.$route.query.tableParams) {
      let tableParams = JSON.parse(this.$route.query.tableParams)

      this.updateParams(tableParams, false)

      // We need to prefill our column filters if set
      Object.keys(tableParams.columnFilters).map(fieldName => {
        // Find the column in our props
        let matchingColumn = this.columnData.find(column => column.field === fieldName)

        if (matchingColumn) {
          matchingColumn.filterOptions.filterValue = tableParams.columnFilters[fieldName]
        }
      })
    }

    this.fetchData()
  },
  methods: {
    updateParams(newProps, updateUrl = true) {
      this.searchParams = Object.assign({}, this.searchParams, newProps)

      console.log('this.searchParams', this.searchParams)

      if (updateUrl) {
        this.updateUrl()
      }
    },

    updateUrl() {
      let tableParamsString = JSON.stringify(this.searchParams)

      this.$router
        .push({ ...this.$route, query: { ...this.$route.query, tableParams: tableParamsString } })
        .catch(() => {})
    },

    onPageChange(params) {
      this.setPage(params.currentPage)

      this.fetchData()
    },

    setPage(pageNumber) {
      this.updateParams({ page: pageNumber })
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })

      this.setPage(1)

      this.fetchData()
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field
          }
        ]
      })

      this.setPage(1)

      this.fetchData()
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.setPage(1)

      this.fetchData()
    },

    // load items is what brings back the rows from server
    async fetchData() {
      let urlParams = {
        per_page: this.searchParams.perPage,
        page: this.searchParams.page,
        fields: []
      }

      if (this.searchParams.sort.length) {
        urlParams.sort = `${this.searchParams.sort[0].type === 'asc' ? '' : '-'}${this.searchParams.sort[0].field}`
      }

      Object.keys(this.searchParams.columnFilters).map(fieldName => {
        if (this.searchParams.columnFilters[fieldName] !== '') {
          urlParams.fields.push(`${fieldName}|${this.searchParams.columnFilters[fieldName]}`)
        }
      })

      await this.api.get(this.apiPath, { ...urlParams })

      console.log('Data', this.api.data)

      if (typeof this.api.data.total !== 'undefined') {
        this.totalRecords = this.api.data.total
        this.rows = this.api.data[this.dataPath]
      }
    }
  }
}
</script>

<style lang="scss">
@import 'node_modules/vue-good-table/src/styles/style.scss';

.data-table.no-data-yet .vgt-loading {
  margin-top: 50px;
  background: #fff;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.data-table.has-data .vgt-loading {
  @apply flex flex-col justify-center items-center top-50 bottom-0 overflow-x-hidden overflow-y-scroll mt-0;
  background: rgba(255, 255, 255, 0.4);
}

.data-table tr:hover {
  @apply cursor-pointer;
  background: ivory !important;
}
</style>
