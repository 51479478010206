<template>
  <UtilTag
    @click="$emit('click')"
    class="inline-block text-base"
    :class="compStatusClass"
    :small="small"
    :medium="medium"
  >
    <slot />
  </UtilTag>
</template>

<script>
import UtilTag from '@/components/utils/UtilTag.vue'

export default {
  name: 'UtilTagStatus',
  components: {
    UtilTag
  },
  props: {
    status: {
      type: String,
      required: true
    },
    small: Boolean,
    medium: Boolean
  },
  computed: {
    compStatusClass() {
      let statusConfig = {
        NEW: 'bg-red-600 text-white',
        IN_PROGRESS: 'bg-orange-500 text-white',
        WON: 'bg-green-500 text-white',
        LOST: 'bg-blue-300 text-white'
      }

      return statusConfig[this.status]
    }
  }
}
</script>

<style lang="scss" scoped></style>
