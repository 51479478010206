<template>
  <div class="placeholder"></div>
</template>

<script>
export default {
  name: 'UtilPlaceholder',
  mounted() {}
}
</script>

<style lang="scss" scoped>
.placeholder {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  background-color: #dddbdd;
  visibility: hidden;
  animation-name: toggleVisibility;
  animation-iteration-count: 1;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 5s infinite;
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

@keyframes toggleVisibility {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
</style>
