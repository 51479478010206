var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VueGoodTable',{staticClass:"data-table",class:{ 'no-data-yet': _vm.rows.length === 0, 'has-data': _vm.rows.length > 0 },attrs:{"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.api.isLoading,"pagination-options":{
    enabled: true,
    perPage: _vm.searchParams.perPage
  },"sort-options":{
    enabled: true
  },"search-options":{
    enabled: false
  },"select-options":{
    enabled: _vm.canSelectRows,
    selectionInfoClass: 'flex items-center'
  },"rows":_vm.rows,"columns":_vm.columnData,"fixed-header":_vm.$breakpoint.smAndUp,"max-height":"70vh","styleClass":"vgt-table striped"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-row-click":function (params) { return _vm.$emit('on-row-click', params); }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'status')?_c('span',[_c('UtilTagStatus',{attrs:{"status":props.row.status,"medium":""}},[_vm._v("\n        "+_vm._s(props.formattedRow[props.column.field])+"\n      ")])],1):_c('span',[_vm._v("\n      "+_vm._s(props.formattedRow[props.column.field])+"\n    ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('UtilMessage',{attrs:{"type":"information"}},[_vm._v("No data for this view")])],1),_vm._v(" "),_vm._v(" "),_c('template',{slot:"loadingContent"},[(_vm.rows.length === 0)?_vm._l((20),function(i){return _c('UtilPlaceholder',{key:i,staticClass:"h-12 mb-1"})}):[_c('div',{staticClass:"bg-white rounded shadow-lg px-6 py-4"},[_c('FontAwesomeIcon',{staticClass:"mr-2",attrs:{"icon":_vm.faSpinnerThird,"spin":"","fixedWidth":""}}),_vm._v("\n        Loading\n      ")],1)]],2),_vm._v(" "),_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('UtilButton',{staticClass:"ml-6",attrs:{"color":"primary","small":""}},[_vm._v("Delete Selected")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }