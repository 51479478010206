<template>
  <div class="tag" @click="$emit('click')" :class="{ small, medium }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UtilTag',
  props: {
    small: Boolean,
    medium: Boolean
  }
}
</script>

<style lang="scss" scoped>
.tag {
  @apply rounded font-medium uppercase select-none leading-tight;

  & + .tag {
    @apply ml-1;
  }
}

.tag.small {
  @apply text-xs px-2 py-1;
}

.tag.medium {
  @apply text-sm px-2 py-1;
}
</style>
